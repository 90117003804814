import React, { useEffect, useState } from "react";
import {
  generateYear_select,
  getCurrentMonth,
  getCurrentYear
} from "../../utils/dateUtils";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import SelectInput from "../../components/selectInput";
import { callApiAsync, createBillingPeriodV2 } from "../../services/apiService";
import ToastNotification from "../../components/toastNotification";
import ModalComponent from "../../components/modalComponent";
import { months_select } from "../../utils/selectsValues";
import LaddaButton from "../../components/button/laddaButton";

const CreatePeriodModal = ({
  open,
  onClose,
  billingUnit,
  bussinessUnit,
  onAddNewPeriod,
  periodSelectData = []
}) => {
  const [loading, setLoading] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastOpen, setToastOpen] = useState(false);
  const [toastSeverity, setToastSeverity] = useState("success");

  const [monthOptions, setMonthOptions] = useState([]);
  const [yearOptions, setYearOptions] = useState([]);

  const schema = yup.object().shape({
    month: yup.string().required("El mes es requerido"),
    year: yup.string().required("El año es requerido")
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { isValid }
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: {
      month: getCurrentMonth(),
      year: getCurrentYear()
    }
  });

  const onCloseModal = () => {
    reset(); 
    onClose();
  };

  const handleFormSubmit = async (formData) => {
    setLoading(true);
    const finalFormData = {
      month: parseInt(formData.month),
      year: parseInt(formData.year),
      bussinesUnitId: bussinessUnit,
      billingUnitId: billingUnit
    };

    await callApiAsync(
      () => createBillingPeriodV2(finalFormData),
      (data) => {
        setToastMessage(
          `Se agregó correctamente el periodo (${formData.month}/${formData.year}).`
        );
        setToastSeverity("success");
        setToastOpen(true);

        onAddNewPeriod(data);

        onCloseModal()
      },
      () => {
        setToastMessage("Error al crear el período.");
        setToastSeverity("error");
        setToastOpen(true);
      }
    );
    setLoading(false);
  };

  const isMonthExists = (month, year) => {
    console.log("Checking month and year:", { month, year });
    return periodSelectData.some((period) => {
      const [monthLabel, periodYear] = period.label.split(" - ");
      const periodMonth = months_select.find(
        (m) => m.label.trim() === monthLabel.trim()
      )?.value;
  
      console.log("Period being checked:", {
        periodLabel: period.label,
        extractedMonth: periodMonth,
        extractedYear: parseInt(periodYear, 10),
      });
  
      return periodMonth === month && parseInt(periodYear, 10) === year;
    });
  };
  
  const getMonthYearSelect = () => {
    const currentMonth = parseInt(getCurrentMonth());
    const currentYear = parseInt(getCurrentYear());
    const previousMonth = currentMonth === 1 ? 12 : currentMonth - 1;
    const previousYear = currentMonth === 1 ? currentYear - 1 : currentYear;
  
    console.log("Current month and year:", currentMonth, currentYear);
    console.log("Previous month and year:", previousMonth, previousYear);
  
    const isCurrentMonthExists = isMonthExists(currentMonth, currentYear);
    const isPreviousMonthExists = isMonthExists(previousMonth, previousYear);
  
    const availableMonths = months_select.filter((month) => {
      if (month.value === currentMonth && !isCurrentMonthExists) return true;
      if (month.value === previousMonth && !isPreviousMonthExists) return true;
      return false;
    });
  
    const availableYears = generateYear_select().filter((year) => {
      if (year.value === currentYear && !isCurrentMonthExists) return true;
      if (year.value === previousYear && !isPreviousMonthExists) return true;
      return false;
    });
  
    console.log("Filtered availableMonths:", availableMonths);
    console.log("Filtered availableYears:", availableYears);
  
    return { availableMonths, availableYears };
  };

  useEffect(() => {
    const { availableMonths, availableYears } = getMonthYearSelect();
    setMonthOptions(availableMonths);
    setYearOptions(availableYears);
  }, [open]);

  return (
    <div>
      <ModalComponent
        customWidth={"600px"}
        open={open}
        onClose={!loading ? onCloseModal : null}
        title={"Selecciona un periodo"}
      >
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <div className="mt-10">
            <SelectInput
              name="month"
              control={control}
              label="Mes"
              options={monthOptions}
              margin="10px 0 0 0 "
            />
          </div>
          <div className="mt-10">
            <SelectInput
              name="year"
              control={control}
              label="Año"
              options={yearOptions}
              margin="10px 0 0 0 "
            />
          </div>
          <div className="HContainer j-center mt-20">
            <LaddaButton
              type="submit"
              loading={loading}
              backgroundColor="#F6C079"
              textColor="#000000"
              width="160px"
              disabled={!isValid}
            >
              Guardar
            </LaddaButton>
          </div>
        </form>
      </ModalComponent>

      <ToastNotification
        open={toastOpen}
        handleClose={() => setToastOpen(false)}
        message={toastMessage}
        severity={toastSeverity}
      />
    </div>
  );
};

export default CreatePeriodModal;
