import React, { useMemo, useState, useEffect } from "react";
import ButtonComponent from "../../components/button";
import icons from "../../constants/icons";
import {
  actionsColumn,
  hideColumn,
  moneyColumn,
  monthColumn,
  textColumn
} from "../../components/tableComponent/columns";
import TableComponent from "../../components/tableComponent";
import { callApiAsync, getPricingInstance } from "../../services/apiService";
import { Link } from "react-router-dom";

const PrincingInstace = () => {
  const [selectedRow, setSelectedRow] = useState({});
  const [openCreateEdit, setOpenCreateEdit] = useState(false);
  const [princingInstaceTable, setPrincingInstaceTable] = useState([]);

  const getPrincingInstace = () => {
    callApiAsync(
      () => getPricingInstance({ isActive: true }),
      (data) => {
        setPrincingInstaceTable(data);
      }
    );
  };

  useEffect(() => {
    getPrincingInstace();
  }, []);

  const handleClose = () => {
    setOpenCreateEdit(false);
  };

  const handleTableDelete = (row) => {
    setSelectedRow(row);
  };

  const actionBtn = ({ row }) => (
    <div className="HContainer">
      <Link
        to={`/PricingInstance/CreateEditPricingInstance/${row.original.id}`}
        state={{ rowData: row.original }}
        style={{ textDecoration: "none" }}
      >
        <ButtonComponent variant="contained" backgroundColor="#fff0">
          <img className="btn-icon" src={icons.blue_edit} alt="icon" />
        </ButtonComponent>
      </Link>
    </div>
  );

  const columns = useMemo(
    () => [
      hideColumn("idBillingPeriod", "IdBillingPeriod"),
      monthColumn("month", "Mes de facturación", 50),
      textColumn("year", "Año de facturación", 50),
      hideColumn("idPackage", "IdPackage"),
      textColumn("name", "Nombre del paquete"),
      moneyColumn("individualPrice", "Precio individual"),
      textColumn("packageSize", "Tamaño del paquete"),
      moneyColumn("packagePrice", "Precio del paquete"),
      textColumn("maxMembersToCharge", "Máximo de miembros a cobrar"),
      hideColumn("currencyId", "CurrencyId"),
      textColumn("siglas", "Moneda", 50),
      moneyColumn("tax", "Tax"),
      actionsColumn(actionBtn)
    ],
    []
  );

  return (
    <div>
      <h1>Elementos facturación</h1>
      <div>
        <TableComponent
          columns={columns}
          data={princingInstaceTable}
          enableGlobalFilter={true}
          headerButtons={() =>
            <Link
              to="/PricingInstance/CreateEditPricingInstance"
              style={{ textDecoration: "none" }}
            >
              <ButtonComponent
                type="submit"
                variant="contained"
                backgroundColor="#F6C079"
                textColor="#000000"
                margin="15px 0"
              >
                <div className="HContainer a-center">
                  <img
                    className="btn-icon icon-margin-r-15"
                    src={icons.plus}
                    alt="icon"
                  />
                  <p>Crear elementos facturación</p>
                </div>
              </ButtonComponent>
            </Link>
          }
        />
      </div>
    </div>
  );
};

export default PrincingInstace;
