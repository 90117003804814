import React from "react";
import { Button } from "@mui/material";

const ButtonComponent = ({
  children,
  onClick,
  type = "button",
  className = "",
  variant = "contained",
  backgroundColor = "#007bff",
  textColor = "#ffffff",
  disable = false,
  height = "40px",
  margin = "0px",
  width = "auto"
}) => {
  return (
    <div>
      <Button
        type={type}
        onClick={onClick}
        className={`nova-billing-btn ${className}`}
        variant={variant}
        style={{
          backgroundColor: backgroundColor,
          color: textColor,
          boxShadow: "none",
          textTransform: "none",
          margin: margin,
          height: height,
          width: width
        }}
        disabled={disable}
      >
        {children}
      </Button>
    </div>
  );
};

export default ButtonComponent;
