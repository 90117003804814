import React, { useState, useEffect } from "react";
import { Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import { InputAdornment } from "@mui/material";

const StyledHelperText = styled('p')({
  color: '#3f51b5',
  margin: 0,
  fontSize: '0.75rem',
});

const TextInput = ({
  name,
  control,
  label,
  defaultValue = "",
  rules = {},
  isTextArea = false,
  rows = 4,
  disabled = false,
  maxLength,
  margin,
  type = "text",
  endAdornment = null
}) => {
  const [charCount, setCharCount] = useState(defaultValue.length);

  useEffect(() => {
    setCharCount(defaultValue.length);
  }, [defaultValue]);

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={{
        ...rules,
        ...(maxLength && { 
          maxLength: {
            value: maxLength,
            message: `No puede exceder los ${maxLength} caracteres`,
          },
        }),
      }}
      render={({ field, fieldState: { error } }) => (
        <div style={ margin ? { margin: margin } : { paddingTop: "15px" }}>
          <TextField
            {...field}
            label={label}
            variant="outlined"
            error={!!error}
            helperText={
              error 
                ? error.message 
                : maxLength 
                ? <StyledHelperText>{`${charCount}/${maxLength} caracteres`}</StyledHelperText>
                : null
            }
            fullWidth
            multiline={isTextArea}
            rows={isTextArea ? rows : 1}
            disabled={disabled}
            onChange={(e) => {
              setCharCount(e.target.value.length); 
              field.onChange(e);
            }}
            type={type}
            slotProps={{
              input: {
                endAdornment: endAdornment
              }
            }}
          />
        </div>
      )}
    />
  );
};

export default TextInput;
