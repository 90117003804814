import React, { useEffect, useState, useMemo } from "react";
import {
  callApiAsync,
  getBillingSelect,
  getFeaturesBilling,
  getPeriodUserTableData,
  postFeatureToBilling
} from "../../services/apiService";
import TableCheckComponent from "../../components/tableCheckComponent";
import ButtonComponent from "../../components/button";
import icons from "../../constants/icons";
import ToastNotification from "../../components/toastNotification";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Grid from "@mui/material/Grid2";
import SearchableSelectInput from "../../components/selectInput/searchableSelectInput";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import DetailsModal from "./detailsModal";
import CreatePeriodModal from "./createPeriodModal";
import BillingDetailsModal from "./billingDetailsModal";
import TableWithTotalsComponent from "../../components/totalTableComponent";
import { getCurrentMonth, getCurrentYear } from "../../utils/dateUtils";
import {
  actionsColumn,
  hideColumn,
  textColumn
} from "../../components/tableComponent/columns";
import { months_select } from "../../utils/selectsValues";

const schema = yup.object().shape({});

const Billing = () => {
  const [billingUnitData, setBillingUnitData] = useState([]);
  const [bussinesUnitData, setBussinesUnitData] = useState([]);
  const [billingPeriodsData, setBillingPeriodsData] = useState([]);

  const [disableBtn, setDisableBtn] = useState(true);

  const [filteredBussinesUnits, setFilteredBussinesUnits] = useState([]);
  const [filteredBillingPeriods, setFilteredBillingPeriods] = useState([]);

  const [billingData, setBillingData] = useState([]);
  const [featuresBilling, setfeaturesBilling] = useState([]);

  const [openModal, setOpenModal] = useState(false);
  const [openBilligDetailModal, setOpenBilligDetailModal] = useState(false);
  const [openPeriodModal, setOpenPeriodModal] = useState(false);
  const [selectedOpenRow, setSelectedOpenRow] = useState({});
  const [selectedOpenBillingRow, setSelectedOpenBillingRow] = useState({});

  const [toastMessage, setToastMessage] = useState("");
  const [toastOpen, setToastOpen] = useState(false);
  const [toastSeverity, setToastSeverity] = useState("success");

  const { control, handleSubmit, reset, watch, setValue } = useForm({
    resolver: yupResolver(schema)
  });

  const watchBillingUnit = watch("billingUnit");
  const watchBussinessUnit = watch("bussinessUnit");
  const watchPeriod = watch("period");

  const getPeriodUserTable = () => {
    callApiAsync(
      () => getPeriodUserTableData(watchPeriod),
      (data) => {
        setBillingData(data);
      }
    );
  };

  const getFeaturesBillingTable = () => {
    callApiAsync(
      () => getFeaturesBilling(watchPeriod, watchBussinessUnit),
      (data) => {
        setfeaturesBilling(data);
      }
    );
  };

  const updateTables = () => {
    getFeaturesBillingTable();
    getPeriodUserTable();
  };

  const getSelects = () => {
    callApiAsync(
      () => getBillingSelect(),
      (data) => {
        setBillingUnitData(data.billingUnit);
        setBussinesUnitData(data.bussinesUnit);
        setBillingPeriodsData(data.billingPeriods);
      }
    );
  };

  const clearFilters = () => {
    reset({
      billingUnit: null,
      bussinessUnit: null,
      period: null
    });

    setFilteredBussinesUnits([]);
    setFilteredBillingPeriods([]);
  };

  const openModalFunction = (data) => {
    setOpenModal(true);
    setSelectedOpenRow(data);
  };

  const openBilligDetailModalFunction = (data) => {
    setOpenBilligDetailModal(true);
    setSelectedOpenBillingRow(data);
  };

  const openPeriodModalFunction = () => {
    const currentMonth = parseInt(getCurrentMonth());
    const currentYear = parseInt(getCurrentYear());
    const previousMonthYear =
      currentMonth === 1
        ? { month: 12, year: currentYear - 1 }
        : { month: currentMonth - 1, year: currentYear };

    const periodExists = filteredBillingPeriods.some((period) => {
      const [labelMonth, labelYear] = period.label.split(" - ");
      const month = months_select.find((m) => m.label === labelMonth)?.value;
      const year = parseInt(labelYear);

      return month === currentMonth && year === currentYear;
    });

    if (periodExists) {
      setToastMessage("No hay periodos disponibles para creación.");
      setToastSeverity("info");
      setToastOpen(true);
      return;
    }

    setOpenPeriodModal(true);
  };

  const closeModal = () => {
    setOpenModal(false);
    setOpenBilligDetailModal(false);
    setOpenPeriodModal(false);
  };

  const columns = useMemo(
    () => [
      hideColumn("productId", "Product ID"),
      { accessorKey: "productName", header: "Producto" },
      hideColumn("featureId", "Feature ID"),
      { accessorKey: "featureName", header: "Característica" },
      { accessorKey: "periodUserCount", header: "# de Usuarios" },
      actionsColumn(
        ({ row }) => (
          <div className="HContainer">
            <ButtonComponent
              onClick={() => openModalFunction(row.original)}
              variant="contained"
              backgroundColor="#fff0"
            >
              <img
                className="btn-icon w-icon-15"
                src={icons.eyeGreen}
                alt="icon"
              />
            </ButtonComponent>
          </div>
        ),
        100
      )
    ],
    []
  );

  const billingColumns = [
    hideColumn("id", "ID"),
    { accessorKey: "mainProduct", header: "Producto" },
    { accessorKey: "featureKey", header: "Característica" },
    { accessorKey: "userCount", header: "# de Usuarios" },
    { accessorKey: "unitPrice", header: "Precio unitario" },
    {
      accessorKey: "total",
      header: "Precio Total",
      Cell: ({ row }) => {
        return <p>{row.original.total.toFixed(2) || 0}</p>;
      },
      Footer: ({ table }) => {
        const totalSum = table
          .getPrePaginationRowModel()
          .rows.reduce((sum, row) => sum + (row.original.total || 0), 0)
          .toFixed(2);

        return (
          <Box>
            <Typography fontWeight="bold">Total:</Typography>
            <Box color="warning.main">{totalSum}</Box>
          </Box>
        );
      }
    },
    { accessorKey: "discount", header: "% Descuento" },
    actionsColumn(
      ({ row }) => (
        <div className="HContainer">
          <ButtonComponent
            onClick={() => openBilligDetailModalFunction(row.original)}
            variant="contained"
            backgroundColor="#fff0"
          >
            <img
              className="btn-icon w-icon-15"
              src={icons.blue_edit}
              alt="icon"
            />
          </ButtonComponent>
        </div>
      ),
      100
    )
  ];

  const addFeatureToBilling = (dataToBilling) => {
    const dataToSend = {
      FeatureIdList: dataToBilling.map((x) => x.featureId),
      BillingPeriodId: watchPeriod,
      BussinesUnitId: watchBussinessUnit
    };

    callApiAsync(
      () => postFeatureToBilling(dataToSend),
      () => {
        updateTables();
      },
      (error) => {
        console.error("Error al agregar la característica:", error);
      }
    );
  };

  useEffect(() => {
    getSelects();
  }, []);

  useEffect(() => {
    if (watchBillingUnit) {
      const filteredUnits = bussinesUnitData.filter(
        (unit) => unit.idBillingUnit === watchBillingUnit
      );
      setFilteredBussinesUnits(filteredUnits);
      setValue("bussinessUnit", "");
      setValue("period", "");
      setFilteredBillingPeriods([]);
      setBillingData([]);
      setfeaturesBilling([]);
    } else {
      setFilteredBussinesUnits([]);
      setFilteredBillingPeriods([]);
    }
  }, [watchBillingUnit]);

  useEffect(() => {
    if (watchBussinessUnit) {
      const filteredPeriods = billingPeriodsData.filter(
        (period) => period.businessUnitId === watchBussinessUnit
      );
      setFilteredBillingPeriods(filteredPeriods);
      setValue("period", "");
      setBillingData([]);
      setfeaturesBilling([]);
    } else {
      setFilteredBillingPeriods([]);
    }
  }, [watchBillingUnit, watchBussinessUnit]);

  useEffect(() => {
    if (watchBillingUnit && watchBussinessUnit) {
      setDisableBtn(false);
    } else {
      setDisableBtn(true);
    }
  }, [watchBillingUnit, watchBussinessUnit]);

  useEffect(() => {
    if (watchBillingUnit && watchBussinessUnit && watchPeriod) {
      const delay = setTimeout(() => {
        getPeriodUserTable();
        getFeaturesBillingTable();
      }, 100);

      return () => clearTimeout(delay);
    } else {
      setBillingData([]);
      setfeaturesBilling([]);
    }
  }, [watchBillingUnit, watchBussinessUnit, watchPeriod]);
  const onSubmit = (formData) => {
    console.log(formData);
  };

  return (
    <div>
      <h1>Facturación</h1>
      <div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid size={{ xs: 12, md: 3 }}>
              <SearchableSelectInput
                name="billingUnit"
                control={control}
                label="Unidad de facturación"
                options={billingUnitData ? billingUnitData : []}
              />
            </Grid>
            <Grid size={{ xs: 12, md: 3 }}>
              <SearchableSelectInput
                name="bussinessUnit"
                control={control}
                label="Unidad de negocio"
                options={filteredBussinesUnits}
              />
            </Grid>
            <Grid size={{ xs: 12, md: 3 }}>
              <SearchableSelectInput
                name="period"
                control={control}
                label="Periodo"
                options={filteredBillingPeriods}
              />
            </Grid>
            <Grid size={{ xs: 12, md: 2 }}>
              <div className="HContainer">
                {!disableBtn && (
                  <ButtonComponent
                    height={"56px"}
                    onClick={() => openPeriodModalFunction()}
                    variant="contained"
                  >
                    <p>{"Crear Periodo"}</p>
                  </ButtonComponent>
                )}
                <IconButton onClick={clearFilters} sx={{ ml: 1 }}>
                  <img
                    className="w-icon-25"
                    src={icons.clearFilter}
                    alt="icon"
                  />
                </IconButton>
              </div>
            </Grid>
          </Grid>
        </form>
        <TableCheckComponent
          columns={columns}
          data={billingData}
          alwaysExpand={true}
          btnText={"Agregar Registros"}
          onHandleData={(selectedData) => {
            addFeatureToBilling(selectedData);
          }}
        />
        <div>
          <TableWithTotalsComponent
            columns={billingColumns}
            data={featuresBilling}
            totalColumns={["userAmount", "unitPrice", "total"]}
            enableGlobalFilter={true}
            hideCheckboxes={true}
          />
        </div>
      </div>

      <BillingDetailsModal
        open={openBilligDetailModal}
        onClose={closeModal}
        billingPeriod={watchPeriod}
        bussinesUnitId={watchBussinessUnit}
        rowData={selectedOpenBillingRow}
        getFeaturesBillingTable={getFeaturesBillingTable}
        getPeriodUserTable={getPeriodUserTable}
      />

      <DetailsModal
        open={openModal}
        onClose={closeModal}
        rowData={selectedOpenRow}
        billingPeriod={watchPeriod}
        bussinessUnit={watchBussinessUnit}
        getPeriodUserTable={getPeriodUserTable}
        getFeaturesBillingTable={getFeaturesBillingTable}
      />

      <CreatePeriodModal
        periodSelectData={filteredBillingPeriods}
        open={openPeriodModal}
        onClose={closeModal}
        billingUnit={watchBillingUnit}
        bussinessUnit={watchBussinessUnit}
        onAddNewPeriod={(newPeriod) => {
          setFilteredBillingPeriods((prev) => [...prev, newPeriod]);
          setBillingPeriodsData((prev) => [...prev, newPeriod]);
          getSelects();
          reset({
            ...watch(),
            period: newPeriod.value
          });
        }}
      />
      <ToastNotification
        open={toastOpen}
        handleClose={() => setToastOpen(false)}
        message={toastMessage}
        severity={toastSeverity}
      />
    </div>
  );
};

export default Billing;
