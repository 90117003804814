import React, { useEffect, useMemo, useState } from "react";
import ModalComponent from "../../components/modalComponent";
import TableCheckComponent from "../../components/tableCheckComponent";
import {
  actionsColumn,
  hideColumn,
  textColumn
} from "../../components/tableComponent/columns";
import {
  callApiAsync,
  getUserByFeatureAndPeriod,
  postUserToBilling
} from "../../services/apiService";
import AlertComponent from "../../components/alertModal";
import ToastNotification from "../../components/toastNotification";

const DetailsModal = ({
  rowData,
  open,
  onClose,
  billingPeriod,
  bussinessUnit,
  getPeriodUserTable,
  getFeaturesBillingTable
}) => {
  const [userData, setUserData] = useState([]);
  const [selected, setSelected] = useState([]);
  const [alertOpen, setAlertOpen] = useState(false);

  const [toastMessage, setToastMessage] = useState("");
  const [toastOpen, setToastOpen] = useState(false);
  const [toastSeverity, setToastSeverity] = useState("warning");

  const closeModal = () => {
    onClose();
    setUserData([]);
  };

  const columns = useMemo(
    () => [
      hideColumn("userId", "ID"),
      textColumn("name", "Nombre Usuario"),
      textColumn("email", "Email"),
      textColumn("roles", "Rol")
    ],
    []
  );

  const updateTables = () => {
    getPeriodUserTable();
    getFeaturesBillingTable();
  };

  const handleAdd = (IsBillable) => {
    if (selected.length === 0) {
      setToastMessage("No se ha seleccionado ningún usuario");
      setToastSeverity("warning");
      setToastOpen(true);
      return;
    }

    const userIdList = selected.map((user) => user.userId);
    const dataToSend = {
      UserIdList: userIdList,
      FeatureId: rowData.featureId,
      BillingPeriodId: billingPeriod,
      IsBillable: IsBillable
    };

    callApiAsync(
      () => postUserToBilling(dataToSend),
      (response) => {
        setToastMessage("Usuarios agregados exitosamente");
        setToastSeverity("success");
        setToastOpen(true);
        setAlertOpen(false);
        updateTables();
        closeModal();
      },
      (error) => {
        setToastMessage("Error al agregar los usuarios");
        setToastSeverity("error");
        setToastOpen(true);
      }
    );
  };

  const openModal = (selectedData) => {
    setAlertOpen(true);
    setSelected(selectedData);
  };

  const getUserTable = () => {
    callApiAsync(
      () => getUserByFeatureAndPeriod(rowData.featureId, billingPeriod, bussinessUnit),
      (data) => {
        setUserData(data);
      }
    );
  };

  useEffect(() => {
    getUserTable();
  }, [open]);

  return (
    <ModalComponent
      open={open}
      onClose={closeModal}
      title={` ${rowData?.productName + " "} Detalle Facturación`}
    >
      <TableCheckComponent
        columns={columns}
        data={userData}
        enableGlobalFilter={true}
        btnText={"Agregar Registro"}
        onHandleData={(selectedData) => {
          if (selectedData.length > 0) {
            openModal(selectedData);
          } else {
            setToastMessage("No se ha seleccionado ningún usuario");
            setToastOpen(true);
          }
        }}
      />
   <AlertComponent
        open={alertOpen}
        onClose={() => setAlertOpen(false)}
        onConfirm={() => handleAdd(true)}
        onDeny={() => handleAdd(false)}
        title="¿Facturables?"
        message={`¿Los ${selected.length} usuarios seleccionados son facturables?`}
        severity="warning"
      />

      <ToastNotification
        open={toastOpen}
        handleClose={() => setToastOpen(false)}
        message={toastMessage}
        severity={toastSeverity}
      />
    </ModalComponent>
  );
};

export default DetailsModal;
