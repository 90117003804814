const icons = {
  plus: "/icons/plus.svg",
  blue_edit: "/icons/blue_edit.svg",
  red_delete: "/icons/red_delete.svg",
  start: "/icons/start.svg",
  alert_red: "/icons/alert_red.svg",
  alert_yellow: "/icons/alert_yellow.svg",
  bill: "/icons/bill.svg",
  menu: "/icons/menu.svg",
  clearFilter: "/icons/clearFilter.svg",
  filter: "/icons/filter.svg",
  eyeGreen: "/icons/eyeGreen.svg",
  userMenu: "/icons/userMenu.svg"
};

export default icons;
