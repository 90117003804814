import React, { useEffect, useMemo } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
  createMRTColumnHelper
} from "material-react-table";
import { Box, Button, IconButton, TextField, Typography } from "@mui/material";
import ButtonComponent from "../button";
import { spanishTableText } from "../tableComponent/tableTexts";
import icons from "../../constants/icons";
import FilterListIcon from "@mui/icons-material/FilterList";

const columnHelper = createMRTColumnHelper();

const TableCheckComponent = ({
  columns,
  data,
  btnText,
  onHandleData,
  enableGlobalFilter = false,
  headerButtons = null
}) => {
  const table = useMaterialReactTable({
    columns,
    data,
    enableGlobalFilter,
    enableRowSelection: true,
    columnFilterDisplayMode: "popover",
    paginationDisplayMode: "pages",
    positionToolbarAlertBanner: "bottom",
    localization: spanishTableText,
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          padding: "8px",
          flexWrap: "wrap"
        }}
      >
        <ButtonComponent
          onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
          type="submit"
          variant="contained"
          backgroundColor="#F6C079"
          textColor="#000000"
          margin="15px 0"
        >
          <div className="HContainer a-center">
            <img
              className="btn-icon icon-margin-r-15"
              src={icons.plus}
              alt="icon"
            />
            <p>{btnText ? btnText : "Selected Rows"}</p>
          </div>
        </ButtonComponent>
      </Box>
    )
  });

  useEffect(() => {
    table.resetRowSelection();
  }, [data]);

  const handleExportRows = (rows) => {
    const selectedData = rows.map((row) => row.original);
    if (onHandleData) {
      onHandleData(selectedData);
    }
    table.resetRowSelection();
  };

  const renderGlobalFilter = () =>
    enableGlobalFilter && (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <TextField
          placeholder="Buscar..."
          variant="outlined"
          size="small"
          value={table.getState().globalFilter || ""}
          onChange={(e) => table.setGlobalFilter(e.target.value)}
          InputProps={{
            startAdornment: <FilterListIcon sx={{ mr: 1 }} />
          }}
        />
        <IconButton onClick={() => table.setGlobalFilter("")} sx={{ ml: 1 }}>
          <img
            className="w-icon-25 icon-margin-r-15"
            src={icons.clearFilter}
            alt="icon"
          />
        </IconButton>
      </Box>
    );

  const renderTableToolbar = () => (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        mb: 2
      }}
    >
      {renderGlobalFilter()}
      {headerButtons}
    </Box>
  );

  return (
    <>
      {renderTableToolbar()}
      <MaterialReactTable table={table} />
    </>
  );
};

export default TableCheckComponent;
