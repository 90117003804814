import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Button
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/system";

const ModalComponent = ({
  open,
  onClose,
  title,
  children,
  onSubmit,
  customWidth
}) => {
  // const StyledDialog = styled(Dialog)(({ theme }) => ({
  //   "& .MuiDialog-paper": {
  //     width: customWidth || "100%",
  //     maxWidth: "calc(100% - 40px)",
  //     margin: "20px",
  //     [theme.breakpoints.down("sm")]: {
  //       width: "calc(100% - 40px)"
  //     }
  //   }
  // }));

  // const StyledDialog = styled(Dialog)(({ theme }) => ({
  //   "& .MuiDialog-paper": {
  //     width: customWidth || "800px",
  //     margin: "20px",
  //   }
  // }));

  const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  }));

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="lg">
      <StyledDialogTitle>
        {title}
        {/* Botón de cierre en el header */}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      </StyledDialogTitle>
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );

  // return (
  //   <Dialog open={open} onClose={onClose} fullWidth>
  //     <DialogTitle>
  //       {title}
  //       {/* Botón de cierre en el header */}
  //       <IconButton
  //         aria-label="close"
  //         onClick={onClose}
  //         sx={{
  //           color: (theme) => theme.palette.grey[500]
  //         }}
  //       >
  //         <CloseIcon />
  //       </IconButton>
  //     </DialogTitle>
  //     <DialogContent>{children}</DialogContent>
  //   </Dialog>
  // );
};

export default ModalComponent;
