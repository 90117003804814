import React from "react";
import LoadingButton from "@mui/lab/LoadingButton";

const LaddaButton = ({
  loading = false,
  onClick,
  children,
  type = "button",
  className = "",
  variant = "contained",
  backgroundColor = "#007bff",
  textColor = "#ffffff",
  disabled = false,
  height = "40px",
  width = "auto"
}) => {
  return (
    <LoadingButton
      type={type}
      onClick={onClick}
      loading={loading}
      variant={variant}
      className={className}
      disabled={disabled || loading}
      sx={{
        backgroundColor: backgroundColor,
        color: textColor,
        height: height,
        width: width,
        textTransform: "none",
        "&:hover": {
          backgroundColor: backgroundColor,
          opacity: 0.9
        }
      }}
    >
      {children}
    </LoadingButton>
  );
};

export default LaddaButton;
