import React, { useEffect, useMemo, useState } from "react";
import TableComponent from "../../../components/tableComponent";
import {
  actionsColumn,
  hideColumn,
  textColumn,
  tooltipColumn
} from "../../../components/tableComponent/columns";
import StatusPill from "../../../components/statusPill";
import BillingPeriod from "../billingPeriod";
import SelectUserModal from "../selectUserModal";
import SelectFeatureModal from "../selectFeaturesModal";
import { Grid } from "@mui/material";
import MoneyTextInput from "../../../components/textInput/moneyTexInput";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate, useParams } from "react-router-dom";
import TextInput from "../../../components/textInput";
import icons from "../../../constants/icons";
import ButtonComponent from "../../../components/button";
import {
  callApiAsync,
  createUpdatePricingInstance,
  getBillingPeriodByPackage,
  getCurrency,
  getEditPricingInstanceData,
  getEnterpriseKeyByPackage,
  getPackage
} from "../../../services/apiService";
import AlertComponent from "../../../components/alertModal";
import { parsePrice } from "../../../utils/formatValues";
import SelectedPackageDisplay from "../selectedPackageDisplay";
import ToastNotification from "../../../components/toastNotification";
import SearchableSelectInput from "../../../components/selectInput/searchableSelectInput";

const schema = yup.object().shape({
  individualPrice: yup.string().required("El precio individual es requerido"),
  packagePrice: yup.string().required("El precio del paquete es requerido"),
  packageSize: yup.string(),
  maxMembersToCharge: yup.string(),
  tax: yup.string(),
  Currency: yup.string().required("La moneda es requerida")
});

function transformCurrencyData(currencyData) {
  return currencyData.map((item) => ({
    value: item.id,
    label: item.siglas.trim()
  }));
}

const CreateEditPricingInstance = () => {
  const [selectedPackage, setSelectedPackage] = useState({});
  const [packageTable, setPackageTable] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);
  const [selectedFeatures, setSelectedFeatures] = useState([]);
  const [openBillingPeriod, setOpenBillingPeriod] = useState(false);
  const [openUserModal, setOpenUserModal] = useState(false);
  const [openFeatureModal, setOpenFeatureModal] = useState(false);

  const [editData, setEditData] = useState({});

  const [billingPeriodSelect, setBillingPeriodSelect] = useState([]);
  const [billingPeriodMessage, setBillingPeriodMessage] = useState(null);
  const [currencyData, setCurrencyData] = useState([]);
  const [enterpriseKey, setEnterpriseKey] = useState("");

  //USER TABLE
  const [UserSelectedRow, setUserSelectedRow] = useState({});
  const [userAlertOpen, setUserAlertOpen] = useState(false);

  //FEATURES TABLE
  const [featureSelectedRow, setFeatureSelectedRow] = useState({});
  const [featureAlertOpen, setFeatureAlertOpen] = useState(false);

  const [toastMessage, setToastMessage] = useState("");
  const [toastOpen, setToastOpen] = useState(false);

  const navigate = useNavigate();
  const { id } = useParams();

  const { control, handleSubmit, setValue } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      individualPrice: "0",
      packagePrice: "0",
      Currency: "1",
      billingPeriod: ""
    }
  });

  const getPackages = () => {
    callApiAsync(
      () => getPackage(true),
      (data) => {
        setPackageTable(data);
      }
    );
  };

  const getCurrencyData = () => {
    callApiAsync(
      () => getCurrency(),
      (data) => {
        setCurrencyData(transformCurrencyData(data));
      }
    );
  };

  const loadEditData = (id) => {
    callApiAsync(
      () => getEditPricingInstanceData(id),
      (data) => {
        setEditData(data);

        setValue("individualPrice", data.individualPrice);
        setValue("packagePrice", data.packagePrice);
        setValue("packageSize", data.packageSize);
        setValue("maxMembersToCharge", data.maxMembersToCharge);
        setValue("tax", data.tax);
        setValue("Currency", data.currencyId.toString());

        setSelectedPackage({
          id: data.idPackage,
          name: data.name
        });

        setSelectedUser(data.selectedUsers);

        const featuresArray = Array.isArray(
          data.selectedFeatures.selectedFeatures
        )
          ? data.selectedFeatures.selectedFeatures
          : [];

        setSelectedFeatures(featuresArray);

        getBillingPeriodByPackageId(data.idPackage);
        setValue("billingPeriod", data.idBillingPeriod.toString());
        getEnterpriseKeyByPackageId(data.idPackage);
      }
    );
  };

  useEffect(() => {
    getPackages();
    getCurrencyData();
    if (id) {
      loadEditData(id);
    }
  }, [id]);

  useEffect(() => {
    if (selectedPackage) {
      if (billingPeriodSelect.length > 0) {
        setValue("billingPeriod", billingPeriodSelect[0].value);
        if (editData) {
          setValue("billingPeriod", editData.idBillingPeriod);
        }
        setBillingPeriodMessage("");
      } else {
        setBillingPeriodMessage(
          "Este paquete no tiene periodos de facturación. Es necesario la creación de un período para este."
        );
        setValue("billingPeriod", "");
      }
    }
  }, [billingPeriodSelect, setValue, selectedPackage]);

  const getBillingPeriodByPackageId = (id) => {
    callApiAsync(
      () => getBillingPeriodByPackage(id),
      (data) => {
        setBillingPeriodSelect(data);
      }
    );
  };

  const getEnterpriseKeyByPackageId = (id) => {
    callApiAsync(
      () => getEnterpriseKeyByPackage(id),
      (data) => {
        setEnterpriseKey(data.enterpriseKey);
      }
    );
  };

  const handlePackageRowClick = (rowData) => {
    setSelectedPackage(rowData);
    getBillingPeriodByPackageId(rowData.id);
    getEnterpriseKeyByPackageId(rowData.id);
  };

  const handleOpenBillingPeriod = () => {
    if (!selectedPackage || !selectedPackage.id) {
      setToastMessage("Es necesario seleccionar un paquete antes de continuar");
      setToastOpen(true);
      return;
    }
    setOpenBillingPeriod(true);
  };

  const handleCloseBillingPeriod = () => {
    setOpenBillingPeriod(false);
  };

  const handleOpenUserModal = () => {
    if (!selectedPackage || !selectedPackage.id) {
      setToastMessage("Es necesario seleccionar un paquete antes de continuar");
      setToastOpen(true);
      return;
    }
    setOpenUserModal(true);
  };

  const handleCloseUserModal = () => {
    setOpenUserModal(false);
  };

  const handleUserSubmit = (selectedUsers) => {
    setSelectedUser(selectedUsers);
    handleCloseUserModal();
  };

  const handleDeleteUser = () => {
    setSelectedUser((prevSelected) =>
      prevSelected.filter((user) => user.id !== UserSelectedRow.id)
    );
    setUserAlertOpen(false);
  };

  const handleOpenFeatureModal = () => {
    setOpenFeatureModal(true);
  };

  const handleCloseFeatureModal = () => {
    setOpenFeatureModal(false);
  };

  const handleFeatureSubmit = (selectedFeatures) => {
    setSelectedFeatures(selectedFeatures);
    handleCloseFeatureModal();
  };

  const handleDeleteFeature = () => {
    setSelectedFeatures((prevSelected) =>
      prevSelected.filter((feature) => feature.id !== featureSelectedRow.id)
    );
    setFeatureAlertOpen(false);
  };

  const handleTableUserDelete = (row) => {
    setUserSelectedRow(row);
    setUserAlertOpen(true);
  };

  const handleTableFeatureDelete = (row) => {
    setFeatureSelectedRow(row);
    setFeatureAlertOpen(true);
  };

  const onSubmit = (formData) => {
    const pricingInstanceData = {
      id: id ? id : undefined,
      currencyId: parseInt(formData.Currency, 10),
      idBillingPeriod: formData.billingPeriod,
      individualPrice: parsePrice(formData.individualPrice),
      maxMembersToCharge: parseInt(formData.maxMembersToCharge, 10),
      packageSize: parseInt(formData.packageSize, 10),
      packagePrice: parsePrice(formData.packagePrice),
      tax: parsePrice(formData.tax),
      idPackage: selectedPackage.id,
      selectedUsers: selectedUser.map((user) => user.id),
      selectedFeatures: selectedFeatures.map((feature) => feature.id)
    };

    savePricingInstance(pricingInstanceData);
  };

  const savePricingInstance = (pricingInstanceData) => {
    callApiAsync(
      () => createUpdatePricingInstance(pricingInstanceData),
      (response) => {
        navigate("/PricingInstance");
      },
      (error) => {}
    );
  };

  const actionBtn = ({ row }) => (
    <div className="HContainer">
      <ButtonComponent
        onClick={() => handleTableUserDelete(row.original)}
        variant="contained"
        backgroundColor="#fff0"
      >
        <img className="btn-icon" src={icons.red_delete} alt="icon" />
      </ButtonComponent>
    </div>
  );

  const featureActionBtn = ({ row }) => (
    <div className="HContainer">
      <ButtonComponent
        onClick={() => handleTableFeatureDelete(row.original)}
        variant="contained"
        backgroundColor="#fff0"
      >
        <img className="btn-icon" src={icons.red_delete} alt="icon" />
      </ButtonComponent>
    </div>
  );

  const userTablecolumns = useMemo(
    () => [
      hideColumn("id", "ID de Usuario"),
      textColumn("email", "Email"),
      actionsColumn(actionBtn)
    ],
    []
  );

  const featuresTableColumn = useMemo(
    () => [
      hideColumn("idMainProduct", "ID del Producto"),
      textColumn("productName", "Nombre del Producto"),
      hideColumn("id", "ID de Feature"),
      textColumn("name", "Nombre del Feature"),
      actionsColumn(featureActionBtn)
    ],
    []
  );

  const selectPackageColumns = useMemo(
    () => [
      hideColumn("id", "id"),
      textColumn("name", "Nombre del Paquete"),
      tooltipColumn("description", "Descripción"),
      hideColumn("idBusinessUnit", "ID de la Unidad de Negocio"),
      textColumn("businessUnitName", "Nombre de la Unidad de Negocio"),
      {
        id: "temporal",
        header: "Temporal",
        Cell: ({ row }) => <>{row.original.temporal === true ? "Si" : "No"}</>
      },
      hideColumn("idCountry", "ID del País"),
      textColumn("countryName", "País"),
      {
        id: "status",
        header: "Estado",
        Cell: ({ row }) => {
          const status = row.original.status;
          return (
            <StatusPill
              backgroundColor={status === 1 ? "#4ADE80" : "#D9D9D9"}
              textColor={"#000000"}
            >
              {status === 1 ? "Activo" : "Inactivo"}
            </StatusPill>
          );
        }
      }
    ],
    []
  );

  return (
    <div>
      <h1>
        {id ? "Editar elementos facturación" : "Crear elementos facturación"}
      </h1>
      <h2>{id ? "Detalles del Paquete" : "Selecciona un Paquete"}</h2>
      {id && selectedPackage ? (
        <SelectedPackageDisplay packageId={selectedPackage.id} />
      ) : (
        <TableComponent
          columns={selectPackageColumns}
          data={packageTable}
          onRowClick={handlePackageRowClick}
          selectedRows={selectedPackage}
        />
      )}

      {selectedPackage && (
        <div style={{ marginTop: "30px" }}>
          <Grid container spacing={2}>
            <Grid item md={4} xs={12}>
              <h2>Periodo de facturación</h2>
            </Grid>
            <Grid item md={4} xs={12}>
              <SearchableSelectInput
                name="billingPeriod"
                control={control}
                label="Periodo de facturación"
                options={billingPeriodSelect}
              />
              {billingPeriodMessage && (
                <p style={{ color: "red", marginTop: "10px" }}>
                  {billingPeriodMessage}
                </p>
              )}
            </Grid>
            <Grid item xs={4}>
              <ButtonComponent
                onClick={handleOpenBillingPeriod}
                variant="contained"
                textColor="#ffff"
              >
                <img
                  className="icon-margin-r-15"
                  src={icons.plus}
                  alt="icono"
                />
                <p>Agregar periodo de facturación</p>
              </ButtonComponent>
            </Grid>
          </Grid>
        </div>
      )}

      <h2>Detalles de facturación</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item md={4} xs={12}>
            <MoneyTextInput
              name="individualPrice"
              control={control}
              label="Precio Individual"
              rules={{ required: "Este campo es requerido" }}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <MoneyTextInput
              name="packagePrice"
              control={control}
              label="Precio del Paquete"
              rules={{ required: "Este campo es requerido" }}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <TextInput
              name="packageSize"
              control={control}
              label="Tamaño del Paquete"
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <TextInput
              name="maxMembersToCharge"
              control={control}
              label="Máximo de Miembros a Cobrar"
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <MoneyTextInput
              name="tax"
              control={control}
              label="Impuesto"
              rules={{ required: "Este campo es requerido" }}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <div style={{ paddingTop: "15px" }}>
              <SearchableSelectInput
                name="Currency"
                control={control}
                label="Moneda"
                options={currencyData}
              />
            </div>
          </Grid>
        </Grid>

        <h2>Selecciona un usuario para facturación.</h2>

        <TableComponent
          columns={userTablecolumns}
          data={selectedUser}
          enableGlobalFilter={true}
          headerButtons={() =>
            <ButtonComponent
              onClick={handleOpenUserModal}
              variant="contained"
              textColor="#ffff"
            >
              <img className="icon-margin-r-15" src={icons.plus} alt="icono" />
              <p>Seleccionar Usuarios</p>
            </ButtonComponent>
          }
        />

        <h2>Selecciona los features</h2>

        <TableComponent
          columns={featuresTableColumn}
          data={selectedFeatures}
          enableGlobalFilter={true}
          headerButtons={() =>
            <ButtonComponent
              onClick={handleOpenFeatureModal}
              variant="contained"
              textColor="#ffff"
            >
              <img className="icon-margin-r-15" src={icons.plus} alt="icono" />
              <p>Seleccionar Features</p>
            </ButtonComponent>
          }
        />

        <div className="HContainer j-center vm-20 mt-20">
          <ButtonComponent type="submit" variant="contained" textColor="#ffff">
            <img className="icon-margin-r-15" src={icons.plus} alt="icono" />
            <p>Agregar PricingInstance</p>
          </ButtonComponent>
        </div>
      </form>

      <BillingPeriod
        open={openBillingPeriod}
        onClose={handleCloseBillingPeriod}
        packageId={selectedPackage}
        onSubmit={getBillingPeriodByPackageId}
      />

      <SelectUserModal
        open={openUserModal}
        onClose={handleCloseUserModal}
        onSubmit={handleUserSubmit}
        selectedUsers={selectedUser}
        enterpriseKey={enterpriseKey}
      />

      <SelectFeatureModal
        open={openFeatureModal}
        onClose={handleCloseFeatureModal}
        onSubmit={handleFeatureSubmit}
        selectedFeatures={selectedFeatures}
      />

      <AlertComponent
        open={userAlertOpen}
        onClose={() => setUserAlertOpen(false)}
        onConfirm={handleDeleteUser}
        title="Confirmar Eliminación"
        message="¿Estás seguro de que deseas eliminar este usuario?"
      />

      <AlertComponent
        open={featureAlertOpen}
        onClose={() => setFeatureAlertOpen(false)}
        onConfirm={handleDeleteFeature}
        title="Confirmar Eliminación"
        message="¿Estás seguro de que deseas eliminar este feature?"
      />

      <ToastNotification
        open={toastOpen}
        handleClose={() => setToastOpen(false)}
        message={toastMessage}
        severity={"warning"}
      />
    </div>
  );
};

export default CreateEditPricingInstance;
