import React, { useMemo } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable
} from "material-react-table";
import { Box, Typography } from "@mui/material";
import ButtonComponent from "../button";
import { spanishTableText } from "../tableComponent/tableTexts";
import icons from "../../constants/icons";

const TableWithTotalsComponent = ({
  columns,
  data,
  totalColumns = [],
  enableGlobalFilter = false,
  headerButtons = null,
  onHandleData,
  showTotals = true,
  alwaysExpand = false,
  hideEyeIconForRows = [],
  hideCheckboxes = false
}) => {
  const table = useMaterialReactTable({
    columns,
    data: data,
    enableGlobalFilter,
    enableRowSelection: !hideCheckboxes,
    getRowId: (row) => row.id || row.userId || "total-row",
    localization: spanishTableText,
    enableStickyFooter: true
  });

  const handleExportRows = (rows) => {
    const selectedData = rows.map((row) => row.original);
    if (onHandleData) {
      onHandleData(selectedData);
    }
  };

  const columnsWithActions = useMemo(() => {
    return columns.map((column) => {
      if (column.accessorKey === "actions") {
        return {
          ...column,
          Cell: ({ row }) => (
            <div className="HContainer">
              {!hideEyeIconForRows.includes(row.original.id) && (
                <ButtonComponent
                  onClick={() => console.log(row.original)}
                  variant="contained"
                  backgroundColor="#fff0"
                >
                  <img
                    className="btn-icon w-icon-15"
                    src={icons.eyeGreen}
                    alt="icon"
                  />
                </ButtonComponent>
              )}
            </div>
          )
        };
      }
      return column;
    });
  }, [columns, hideEyeIconForRows]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2
        }}
      >
        {headerButtons}
      </Box>
      <MaterialReactTable table={table} columns={columnsWithActions} />
    </>
  );
};

export default TableWithTotalsComponent;
