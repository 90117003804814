import http from "./httpService";

export const callActionApiAsync = async (
  apiFunc,
  setData,
  handleError,
  setLoader
) => {
  if (setLoader) setLoader(true);
  try {
    await apiFunc()
      .then(async (response) => {
        if (response) {
          if (setData.constructor.name === "AsyncFunction")
            await setData(response.data);
          else setData(response.data);
        }
      })
      .catch((err) => {
        var error = "";
        if (err.response) {
          if (err.response.data) {
            error = err.response.data;
            console.log("error-response: ", err.response.data);
          } else {
            error = err.response;
            console.log("error-response: ", err.response);
          }
        } else if (err.request) {
          error = err.request;
          console.log("error-request: ", err.request);
        } else {
          error = err;
          console.log("error-error: ", err);
        }

        if (handleError) {
          console.log("handleError: -> ", error);
          handleError(error);
        }
      })
      .then(() => {
        if (setLoader) setLoader(false);
      });
  } catch (e) {
    console.log("error: ", e.message);
  }
};

export const callApiAsync = async (
  apiFunc,
  setData,
  handleError,
  setLoader
) => {
  await callActionApiAsync(apiFunc, setData, handleError, (data) => {
    if (setLoader) setLoader(data);
  });
};

/*Package*/
export const getPackage = (isActive) =>
  http.get(`/api/getPackageList/${isActive}`);
export const packageCreateOrUpdate = (data) =>
  http.post(`/api/packageCreateOrUpdate`, data);
export const deletePackage = (id) => http.delete(`/api/deletePackage/${id}`);
export const getPackageById = (packageId) =>
  http.get(`/api/getPackage/${packageId}`);

/*Products*/
export const getMainProducts = () => http.get(`/api/getMainProducts`);
export const productCreateOrUpdate = (data) =>
  http.post(`/api/productCreateOrUpdate`, data);

/*Features*/
export const getAllFeatures = () => http.get(`/api/getAllFeatures`);
export const getFeaturesByProduct = (productId) =>
  http.get(`/api/getFeaturesByProduct/${productId}`);
export const featuresCreateOrUpdate = (data) =>
  http.post(`/api/featuresCreateOrUpdate`, data);
export const deleteFeature = (id) => http.delete(`/api/deleteFeature/${id}`);

/*BusinessUnit*/
export const getBusinessUnits = () => http.get(`/api/getBusinessUnits`);
export const businessUnitCreateOrUpdate = (data) =>
  http.post(`/api/businessUnitCreateOrUpdate`, data);
export const deleteBussinesUnit = (id) =>
  http.delete(`/api/deleteBusinessUnit/${id}`);

/*BillingUnits*/
export const getBillingUnits = () => http.get(`/api/getBillingUnits`);
export const billingUnitsCreateOrUpdate = (data) =>
  http.post(`/api/billingUnitCreateOrUpdate`, data);

/*Country*/
export const getCountrys = () => http.get(`/api/getCountrys`);
export const countryCreateOrUpdate = (data) =>
  http.post(`/api/countryCreateOrUpdate`, data);

/*PricingInstance*/
export const getPricingInstance = () => http.get(`/api/getPricingInstance`);
export const createUpdatePricingInstance = (data) =>
  http.post(`/api/createUpdatePricingInstance`, data);
export const getEditPricingInstanceData = (idPricingInstance) =>
  http.get(`/api/getEditPricingInstanceData/${idPricingInstance}`);

/*BillingPeriod*/
export const getBillingPeriodByPackage = (id) =>
  http.get(`/api/getBillingPeriodByPackage/${id}`);
export const createBillingPeriod = (data) =>
  http.post(`/api/createBillingPeriod`, data);

/*BillingPeriod*/
export const getCurrency = () => http.get(`/api/getCurrency`);

/*EnterpriseKey*/
export const getEnterpriseKeyByPackage = (packageId) =>
  http.get(`/api/getEnterpriseKeyByPackage/${packageId}`);

/*SysUsers*/
export const getSysUsers = (enterpriseKey) =>
  http.get(`/api/getSysUsers/${enterpriseKey}`);

/*SysUsers*/
export const getSysEnterprise = () => http.get(`/api/getSysEnterprise`);

/*Billing*/
export const getBillingSelect = () => http.get(`/api/getBillingSelectV2`);

export const createBillingPeriodV2 = (data) =>
  http.post(`/api/createBillingPeriodV2`, data);

export const getPeriodUserTableData = (data) =>
  http.get(`/api/getPeriodUser/${data}`);

export const getUserByFeatureAndPeriod = (
  featureId,
  billingUnitId,
  bussinesUnitId
) =>
  http.get(
    `/api/UserByFeatureAndPeriod/${featureId}/${billingUnitId}/${bussinesUnitId}`
  );

export const postFeatureToBilling = (data) =>
  http.post(`/api/AddFeatureToBilling`, data);

export const postUserToBilling = (data) =>
  http.post(`/api/AddUserToBilling`, data);

export const getFeaturesBilling = (billingPeriodId, businessUnitId) => {
  return http.get(
    `/api/GetFeaturesBilling/${billingPeriodId}/${businessUnitId}`
  );
};

export const getUsersBilling = (
  billingPeriodId,
  bussinesUnitId,
  featureId
) => {
  return http.get(
    `/api/GetUsersBilling/${billingPeriodId}/${bussinesUnitId}/${featureId}`
  );
};

export const deleteFeaturePeriodUser = (featurePeriodUserId) =>
  http.post(`/api/DeleteFeaturePeriodUser`, featurePeriodUserId);

export const changeBilledFeature = (data) =>
  http.post(`/api/ChangeBilledFeaturePeriodUser`, data);

export const updateFeaturePeriodCall = (data) =>
  http.post(`/api/UpdateFeaturePeriod`, data);