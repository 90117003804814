import React, { useEffect, useMemo, useState } from "react";

import TableComponent from "../../../components/tableComponent";
import { Button } from "@mui/material";
import {
  actionsColumn,
  textColumn
} from "../../../components/tableComponent/columns";
import CreateEditFeaturesModal from "./createEditFeatureModal";
import {
  callApiAsync,
  deleteFeature,
  featuresCreateOrUpdate,
  getFeaturesByProduct,
  getPackage
} from "../../../services/apiService";
import ModalComponent from "../../../components/modalComponent";
import AlertComponent from "../../../components/alertModal";
import ToastNotification from "../../../components/toastNotification";
import ButtonComponent from "../../../components/button";
import icons from "../../../constants/icons";

const FeaturesModal = ({ rowDataProduct, open, onClose }) => {
  const [openCreateEdit, setOpenCreateEdit] = useState(false);
  // const [openDelete, setDelete] = useState(false);

  const [featuresData, setFeaturesData] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});

  const [alertOpen, setAlertOpen] = useState(false);

  const [toastMessage, setToastMessage] = useState("");
  const [toastOpen, setToastOpen] = useState(false);

  const getFeaturesTable = () => {
    callApiAsync(
      () => getFeaturesByProduct(rowDataProduct.id),
      (data) => {
        setFeaturesData(data);
      }
    );
  };

  useEffect(() => {
    getFeaturesTable();
  }, [rowDataProduct]);

  const createUpdateFeature = (featureData) => {
    callApiAsync(
      () => featuresCreateOrUpdate(featureData),
      (data) => {
        getFeaturesTable();
      }
    );
  };

  const deleteFeatureBtn = (featureData) => {
    callApiAsync(
      () => deleteFeature(featureData.id),
      (data) => {
        getFeaturesTable();
      },
      (err) => {
        const errorMessage = err?.message || err;
        console.log("modal error", err);

        setToastMessage(errorMessage);
        setToastOpen(true);
      }
    );
  };

  const handleCreateEdit = (row) => {
    if (!row) {
      setSelectedRow({});
      setOpenCreateEdit(true);
      return;
    }
    setSelectedRow(row);
    setOpenCreateEdit(true);
  };

  const handleDelete = () => {
    deleteFeatureBtn(selectedRow);
    setSelectedRow({});
  };

  const handleTableDelete = (row) => {
    setSelectedRow(row);
    setAlertOpen(true);
  };

  const actionBtn = ({ row }) => (
    <div className="HContainer">
      <ButtonComponent
        onClick={() => handleCreateEdit(row.original)}
        variant="contained"
        backgroundColor="#fff0"
      >
        <img className="btn-icon" src={icons.blue_edit} alt="icon" />
      </ButtonComponent>
      <ButtonComponent
        onClick={() => handleTableDelete(row.original)}
        variant="contained"
        backgroundColor="#fff0"
      >
        <img className="btn-icon" src={icons.red_delete} alt="icon" />
      </ButtonComponent>
    </div>
  );

  const handleClose = () => {
    setOpenCreateEdit(false);
    // setDelete(false);
  };

  const columns = useMemo(
    () => [
      textColumn("name", "Nombre del Característica / Features"),
      actionsColumn(actionBtn, 50)
    ],
    []
  );

  return (
    <div>
      <ModalComponent
        open={open}
        onClose={onClose}
        title={"Característica / Features"}
      >
        <TableComponent
          columns={columns}
          data={featuresData}
          enableGlobalFilter={true}
          headerButtons={() => (
            <>
              <ButtonComponent
                onClick={() => handleCreateEdit()}
                type="submit"
                variant="contained"
                backgroundColor="#F6C079"
                textColor="#000000"
                margin="15px 0"
              >
                <div className="HContainer a-center">
                  <img
                    className="btn-icon icon-margin-r-15"
                    src={icons.plus}
                    alt="icon"
                  />
                  <p>Crear Característica / Features</p>
                </div>
              </ButtonComponent>
            </>
          )}
        />

        <CreateEditFeaturesModal
          tableData={featuresData}
          rowData={selectedRow}
          open={openCreateEdit}
          onClose={handleClose}
          idMainProduct={rowDataProduct}
          onSubmitFuntion={createUpdateFeature}
        />
      </ModalComponent>
      <AlertComponent
        open={alertOpen}
        onClose={() => setAlertOpen(false)}
        onConfirm={handleDelete}
        title="Confirmar Eliminación"
        message="¿Estás seguro de que deseas eliminar esta caracteristica / features? Esta acción no se puede deshacer."
      />
      <ToastNotification
        // open={snackbarOpen}
        // handleClose={handleCloseSnackbar}
        // message={toastMessage}

        open={toastOpen}
        handleClose={() => setToastOpen(false)}
        message={toastMessage}
        severity={"warning"}
      />
    </div>
  );
};

export default FeaturesModal;
