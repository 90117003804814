import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography
} from "@mui/material";
import icons from "../../constants/icons";
import ButtonComponent from "../button";

const AlertComponent = ({
  open,
  onClose,
  onConfirm,
  onDeny, 
  title,
  message,
  severity = "danger"
}) => {
  const handleNoClick = () => {
    if (onDeny) {
      onDeny(); 
    }
    onClose(); 
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent className="HContainer a-center">
        <img
          className="btn-icon vm-20 hm-15"
          src={severity === "danger" ? icons.alert_red : icons.alert_yellow}
          alt="icon"
        />
        <Typography>{message}</Typography>
      </DialogContent>
      <DialogActions>
        <ButtonComponent
          onClick={handleNoClick} 
          backgroundColor="#fff0"
          textColor={severity === "danger" ? "#D32F2F" : "#F57C00"}
        >
          <p className="b">No</p>
        </ButtonComponent>
        <ButtonComponent
          onClick={() => {
            onConfirm();
          }}
          backgroundColor={severity === "danger" ? "#D32F2F" : "#F57C00"}
          variant="contained"
        >
          <p className="b">Sí</p>
        </ButtonComponent>
      </DialogActions>
    </Dialog>
  );
};

export default AlertComponent;